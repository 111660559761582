import React from 'react';
import Chart from "react-apexcharts";

const columnChart = {
  chart: {
      type: "bar", 
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    tickAmount: 'dataPoints',
    axisTicks: {
        show: false,
    },
  },
  yaxis: {
    tickAmount: 8,
  },
  title: {
      text: 'Total Time',
      align: 'left'
  }
};

const TimeColumnChart = ({ titleOptions, tableData }) => {
    const timeChartSeries = tableData.reduce((acc, data) => {
        const taskNo = parseInt(data.taskNo);
        const xoffset = parseInt(data.xoffset);
        acc.push({ x: taskNo, y: xoffset });
        return acc;
    }, []);

    return (
        <Chart
            options={{...columnChart, ...titleOptions }}
            series={[{
                data: timeChartSeries
            }]}
            type={columnChart.chart.type}
            width="100%"
            height="200px"
        />
    );
};

export default TimeColumnChart;