import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import SessionVREmbed from './SessionVREmbed';
import CogniteSessionResultsTable from './CogniteSessionResultsTable';
import { DialogTitle } from '@material-ui/core';

const PaperComponent = props => (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
    </Draggable>
);

const dialogStyle = {
    root: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        maxWidth: '100%'
    },
};

const NonModalDialog = withStyles(dialogStyle)(props => (
    <Dialog hideBackdrop {...props} />
));

const WindowContent = (sessionData) => {
    if (sessionData.sessionType == "IpgSession") {
        return (
            <img
                src={`data:image/png;base64, ${sessionData.picture}`}
                alt="session data"
                width="100%"
                height="100%"
            /> 
        );
    } else if (sessionData.sessionType == "RehabGameSession") {
        if (sessionData.gameData.length > 0) 
            return <SessionVREmbed sessionId={sessionData.sessionId} commands={sessionData.gameData} module={sessionData.module} />;
        return <label style={{color: "red"}}>No game data found in this session</label>;
    } else if (sessionData.sessionType == "CogniteVRGameSession") {
        if ((sessionData.taskResults.length > 0 || sessionData.polarData && Object.values(sessionData.polarData).flat().length > 0))
            return <CogniteSessionResultsTable polarData={sessionData.polarData} taskResults={sessionData.taskResults} moduleId={sessionData.moduleId} hrvAnalysis={{ SDNN: sessionData.sdnn, SDSD: sessionData.sdsd, RMSSD: sessionData.rmssd, NN50: sessionData.nN50, pNN50: sessionData.pNN50 }} />;
        return <label style={{color: "red"}}>No game data found in this session</label>;
    }
};

const SessionModal = ({ sessionData, togglePanel }) => {
    return (
        <NonModalDialog open={true} PaperComponent={PaperComponent} PaperProps={{elevation: 3}}>
            <DialogContent>
                <DialogTitle>{sessionData.notes}</DialogTitle>
                {WindowContent(sessionData)}
            </DialogContent>
            <DialogActions>
                <Button onClick={togglePanel} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </NonModalDialog>
    );
};

export default SessionModal;
