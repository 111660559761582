import { showNotification } from 'react-admin';

const createEditableRowOps = (dataProvider, dispatch) => ([endpoint, objectId, permissions], customActions = {}) => ({
    isEditable: rowData => permissions.update,
    onRowAdd: permissions.create ?  
        (newData => {
            return new Promise((resolve, reject) => {
                dataProvider
                    .create(endpoint, {
                        data: newData,
                    })
                    .then(json => {
                        if (typeof customActions.onRowAdd === "function") { 
                            customActions.onRowAdd(json.data);
                        }
                        resolve();
                    })
                    .catch(error => {
                        dispatch(
                            showNotification('Failed to add row', 'error', {
                                autoHideDuration: 5000
                        }));
                        reject();
                    });
            });
        }) : null, //no permission to add - no method
    onRowUpdate: permissions.update || permissions.create ? //If the user has ability to add, but not modify, keep the disabled icon so columns don't get messed up when adding new row
    customActions.onRowUpdate ||
        ((newData, oldData) => {
            return new Promise((resolve, reject) => {
                dataProvider
                    .update(endpoint, {
                        id: oldData[objectId],
                        data: newData,
                    })
                    .then(resolvedData => {
                        resolve();
                    })
                    .catch(error => {
                        dispatch(
                            showNotification('Failed to update row', 'error', {
                                autoHideDuration: 5000
                        }));
                        reject();
                    });
            });
        }) : null, //no permission to update - no method,
    onRowDelete: permissions.delete ? customActions.onRowDelete ||
        (oldData =>
            new Promise((resolve, reject) => {
                dataProvider
                    .delete(endpoint, {
                        id: oldData[objectId],
                    })
                    .then(resolvedData => {
                        resolve();
                    })
                    .catch(error => {
                        dispatch(
                            showNotification('Failed to delete row', 'error', {
                                autoHideDuration: 5000
                        }));
                        reject();
                    });
            })
        )  : null, //no permission to delete - no method
});

export default createEditableRowOps;
