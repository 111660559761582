import { RELOAD_ASSIGNED_DATA } from '../constants';
import { LOCATION_CHANGE } from 'react-router-redux';

export default (previousState = {}, { type, payload }) => {
    if (type === RELOAD_ASSIGNED_DATA) {
        return { ...previousState, ...payload };
    }
    else if (type === LOCATION_CHANGE) {
        return { ...{}, ...payload }
    }
    return previousState;
};
