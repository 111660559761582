import React from 'react';
import Chart from "react-apexcharts";
import { q25, median, q75 } from '../../helpers/five-number';

const boxPlotChart = {
    chart: {
        type: 'boxPlot',
        height: 350
    },
    plotOptions: {
        boxPlot: {
            colors: {
                upper: '#5C4742',
                lower: '#A5978B'
            }
        }
    },
    title: {
        text: 'Space Box Plot Chart',
        align: 'left'
    }
};

const BoxPlotChart = ({ titleOptions, tableData }) => {

    const boxPlotData = tableData.reduce((acc, item) => {
        acc[item.x] = { x: item.x + 1, y: [Math.min(...item.y), q25(item.y), median(item.y), q75(item.y), Math.max(...item.y)] };
        return acc;
    }, []).filter(Boolean);

    if (boxPlotData.length == 0)
        return null;

    return (
        <Chart
            options={{...boxPlotChart, ...titleOptions }}
            series={[{
                type: 'boxPlot',
                data: boxPlotData
            }]}
            type={boxPlotChart.chart.type}
            width="100%"
            height="400px"
        /> 
    );
};

export default BoxPlotChart;