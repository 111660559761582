import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDataProvider, Title } from 'react-admin';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import createDataExtension from '../dataExtension';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';

const DevicesList = props => {
    const endpoint = 'devices';
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    const dataExtension = createDataExtension(dataProvider, dispatch, 'modules', 'moduleId', 'name');
    const modulesLookupTable = useSelector(state => state.lookupReducer.modules);
    dataExtension.useLookupNames(modulesLookupTable);

    return (
        <div className="ListLayoutFullWidth">
            <Title title="resources.devices.name" />
            <EnhancedTable
                {...{
                    title: 'resources.devices.name',
                    tableName: endpoint,
                    columns: [
                        { field: 'deviceId', hidden: true },
                        { field: 'moduleId', lookupTable: 'modules' },
                        { field: 'name' },
                        { field: 'macAdress' },
                        { field: 'serialAdress' }
                    ],
                    editable: EditableRowOps([endpoint, 'deviceId', permissions])
                }}
            />
        </div>
    );
};

export default DevicesList;
