import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useTranslate } from 'react-admin';
import MaterialTable from 'material-table';
import { replaceColumns, fetchColumns } from './index';

const EnhancedTable = ({
   title,
   tableName,
   customFilters,
   customOptions,
   customURLParams,
   customActions,
   customData,
   columns,
   outerRef,
   ...props
}) => {
    const actions = customActions || [];
    const tableRef = outerRef || React.createRef();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const latestTableData = useSelector(state => state.tableReducer[title]);
    const enableFiltering = latestTableData && latestTableData.filtering;
    const currentTheme = useSelector(state => state.themeReducer);

    const pageSize = latestTableData ? latestTableData.pageSize : 5;
    const latestColumns = replaceColumns({ i18nKey: tableName, translateFn: translate, columnArray: latestTableData ? latestTableData.columns : columns });

    const toggleFiltering = (event) => {
        tableRef.current && tableRef.current.onQueryChange({ filtering: !enableFiltering });
    };

    const fetchData = useCallback(
        query => fetchColumns(query, title, tableName, dataProvider, dispatch, customFilters, customURLParams, latestColumns),
        [
            tableName,
            customFilters,
            customURLParams
        ]
    );

    return (
        <MaterialTable
            title={translate(title, 2)}
            actions={[{
                    icon: 'refresh',
                    tooltip: translate('enhancedTable.toolbar.refreshData'),
                    isFreeAction: true,
                    hidden: customData ? true : false,
                    onClick: () => tableRef.current && tableRef.current.onQueryChange(), //refresh table
                }, {
                    icon: 'filter_list',
                    tooltip: translate('enhancedTable.toolbar.toggleFiltering'),
                    isFreeAction: true,
                    hidden: customData ? true : false,
                    onClick: event => toggleFiltering(event)
                },
                ...actions
            ]}
            columns={latestColumns}
            data= {customData || (query => { 
                if (typeof(query.filtering) === "undefined" && enableFiltering)
                    query.filtering = enableFiltering;
                return fetchData(query);
            })}
            tableRef={tableRef}
            localization={{
                error: translate("enhancedTable.error"),
                grouping: {
                    groupedBy: translate("enhancedTable.grouping.groupedBy"),
                    placeholder: translate("enhancedTable.grouping.placeholder"),
                },
                pagination: {
                    labelDisplayedRows: translate("enhancedTable.pagination.labelDisplayedRows"),
                    labelRowsPerPage: translate("enhancedTable.pagination.labelRowsPerPage"),
                    labelRowsSelect: translate("enhancedTable.pagination.labelRowsSelect"),
                    firstAriaLabel: translate("enhancedTable.pagination.firstAriaLabel"),
                    firstTooltip: translate("enhancedTable.pagination.firstTooltip"),
                    previousAriaLabel: translate("enhancedTable.pagination.previousAriaLabel"),
                    previousTooltip: translate("enhancedTable.pagination.previousTooltip"),
                    nextAriaLabel: translate("enhancedTable.pagination.nextAriaLabel"),
                    nextTooltip: translate("enhancedTable.pagination.nextTooltip"),
                    lastAriaLabel: translate("enhancedTable.pagination.lastAriaLabel"),
                    lastTooltip: translate("enhancedTable.pagination.lastTooltip"),
                },
                toolbar: {
                    searchTooltip: translate("enhancedTable.toolbar.searchTooltip"),
                    searchPlaceholder: translate("enhancedTable.toolbar.searchPlaceholder"),
                    showColumnsTitle: translate("enhancedTable.toolbar.showColumnsTitle"),
                    showColumnsAriaLabel: translate("enhancedTable.toolbar.showColumnsAriaLabel"),
                    exportTitle: translate("enhancedTable.toolbar.exportTitle"),
                    exportAriaLabel: translate("enhancedTable.toolbar.exportAriaLabel"),
                    exportName: translate("enhancedTable.toolbar.exportName"),
                    exportCSVName: translate("enhancedTable.toolbar.exportCSVName"),
                    exportPDFName: translate("enhancedTable.toolbar.exportPDFName"),
                },
                header: {
                    actions: translate("enhancedTable.header.actions"),
                },
                body: {
                    filterRow: {
                        filterTooltip: translate("enhancedTable.body.filterRow.filterTooltip")
                    },
                    editRow: {
                        saveTooltip: translate("enhancedTable.body.editRow.saveTooltip"),
                        cancelTooltip: translate("enhancedTable.body.editRow.cancelTooltip"),
                        deleteText: translate("enhancedTable.body.editRow.deleteText"),
                    },
                    addTooltip: translate("enhancedTable.body.addTooltip"),
                    deleteTooltip: translate("enhancedTable.body.deleteTooltip"),
                    editTooltip: translate("enhancedTable.body.editTooltip"),
                    bulkEditTooltip: translate("enhancedTable.body.bulkEditTooltip"),
                    bulkEditApprove: translate("enhancedTable.body.bulkEditApprove"),
                    bulkEditCancel: translate("enhancedTable.body.bulkEditCancel"),
                }
            }}
            options={{
                search: false,
                padding: 'dense',
                pageSize: pageSize,
                headerStyle: {
                    backgroundColor: currentTheme === "light" ? "#f9f9f9" : "#585858",
                },
                cellStyle: {
                    fontSize: "0.875rem",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                },
                filtering: enableFiltering,
                ...customOptions,
            }}
            {...props}
        />
    );
};

export default EnhancedTable;
