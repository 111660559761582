import React from 'react';
import { Tab, TabbedShowLayout } from 'react-admin';
import MaterialTable from 'material-table';
import EnhancedTable from '../enhancedTable/EnhancedTable';

const SpecialistTabs = ({ useSelector, translate }) => {
    const tabsInfo = useSelector(state => state.tabsReducer);

    return (
        <TabbedShowLayout>
            <Tab label={translate('resources.specialists.tabs.assignedPatients')}>   
                    <EnhancedTable
                        {...{
                            title: 'resources.patients.name',
                            tableName: 'patients',
                            columns: [
                                { field: 'id', hidden: true },
                                { field: 'userName' }
                                // { field: 'currentDepartmentId', type: 'numeric', lookup: departmentsLookupTable },
                            ],
                            static_data: tabsInfo.assignedPatients,
                            key: tabsInfo.assignedPatients && tabsInfo.assignedPatients.length,
                            customOptions: {
                                exportButton: true,
                                toolbar: false,
                                padding: 'dense',
                            },
                        }}
                    />
            </Tab>
            <Tab label={translate('resources.specialists.tabs.departments')} key="Departments">
                <MaterialTable
                    columns={[
                        {
                            title: translate('resources.departments.fields.departmentId'),
                            field: 'id',
                        },
                        {
                            title: translate('resources.departments.fields.name'),
                            field: 'name',
                        },
                        {
                            title: translate('resources.departments.fields.isMain'),
                            field: 'isMain',
                            type: 'boolean',
                        },
                    ]}
                    data={[
                        {
                            id: '1',
                            name: 'TESTAS',
                            isMain: true,
                        },
                    ]}
                    options={{
                        exportButton: true,
                        toolbar: false,
                        padding: 'dense',
                    }}
                />
            </Tab>
        </TabbedShowLayout>
    );
};

export default SpecialistTabs;
