import React from 'react';
import SessionCharts from './SessionCharts';
import HRVAnalysis from './SessionHRVTable';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import { Container, Card, CardHeader, CardContent, Button } from '@material-ui/core';

const columns = {
    2: [
        { field: 'taskNo', type: 'numeric' },
        { field: 'afterfake', type: 'numeric' },
        { field: 'correct', type: 'numeric' },
        { field: 'difficultyState', type: 'numeric'},
        { field: 'mistakeafter', type: 'numeric' },
        { field: 'time', type: 'numeric' },
    ],
    3: [
        { field: 'taskNo', type: 'numeric' },
        { field: 'difficultyState', type: 'numeric'},
        { field: 'points_x', type: 'numeric' },
        { field: 'points_y', type: 'numeric' },
        { field: 'xoffset', type: 'numeric' },
        { field: 'yoffset', type: 'numeric' },
    ],
    4: [
        { field: 'taskNo', type: 'numeric', hidden: true },
        { field: 'currentGameTime', type: 'numeric' },
        { field: 'currentDifficulty', type: 'numeric' },
        { field: 'correct'},
        { field: 'GAME_TYPE' },
        { field: 'reactionTime', type: 'numeric' },
        { field: 'selectedCard', type: 'numeric' },
        { field: 'startingNumber', type: 'numeric' },
        { field: 'successfulMoves', type: 'numeric' }
    ]
};

const fitTasks = function(taskResults) {
    return taskResults.reduce((items, element) => {
        const itemIndex = element.taskNumber - 1;
        const item = items[itemIndex] || (items[itemIndex] = {
            taskNo: element.taskNumber
        });

        item[element.key] = element.value;
        return items;
    }, []);
};

const calculateGameResults = (gameData) => {
    const aggregatedGameData = gameData.reduce((results, item) => {
        const currentGameMin = Math.ceil(item.currentGameTime / 60);

        if (!results[currentGameMin]) {
            results[currentGameMin] = {
                gameMinute: currentGameMin,
                correctAnswers: 0,
                mistakenAnswers: 0,
                totalAnswers: 0,
                successPercent: 0,
            };
        }

        results[currentGameMin][item.correct === "true" ? "correctAnswers" : "mistakenAnswers"]++;
        results[currentGameMin].totalAnswers++,
        results[currentGameMin].successPercent = `${((results[currentGameMin].correctAnswers / results[currentGameMin].totalAnswers) * 100).toFixed(2)}%`;
        return results;
    }, {});

    return Object.values(aggregatedGameData);
};

const GameResultsTable = (props) => (
    <EnhancedTable
        style={{ marginBottom: "10px" }} 
        {...{
            title: 'resources.cogniteGameResults.name',
            tableName: 'cogniteGameResults',
            customData: props.gameData,
            columns: [
                { field: 'gameMinute', type: 'numeric' },
                { field: 'totalAnswers', type: 'numeric' },
                { field: 'correctAnswers', type: 'numeric' },
                { field: 'mistakenAnswers', type: 'numeric' },
                { field: 'successPercent', type: 'numeric' },
            ],
            editable: false,
            customOptions: {
                pageSize: Math.min(props.gameData.length, 20),
                exportButton: true,
                exportAllData: true
            }
        }}
    />
);

const CogniteSessionResultsTable = (props) => {
    const tableData = fitTasks(props.taskResults);
    const polarData = props.polarData && JSON.stringify(props.polarData, (_, value) => (typeof value != "object" ? +value : value), 2);

    return (
        <Container className="ListLayoutFullWidth">
            { props.moduleId == 4 && <GameResultsTable gameData={ calculateGameResults(tableData) } /> }
            { tableData.length > 0 && 
                <EnhancedTable
                    {...{
                        title: 'resources.cogniteresults.name',
                        tableName: 'cogniteresults', //For column translation purposes only
                        customData: tableData,
                        columns: columns[props.moduleId],
                        editable: false,
                        customOptions: {
                            pageSize: Math.min(tableData.length, 20),
                            exportButton: true,
                            exportAllData: true
                        }
                    }}
                />
            }
            
            { props.polarData && <SessionCharts polarData={props.polarData} tableData={tableData} moduleId={props.moduleId} /> }
            
            { props.polarData && <HRVAnalysis hrvAnalysis={props.hrvAnalysis} /> }
            
            { polarData &&  
                <Card style={{ marginTop: "10px" }}>
                    <CardHeader 
                        style={{backgroundColor: "#f9f9f9"}}
                        title="Polar Data" 
                        action={
                            <Button variant="contained" style={{ margin: '1em' }} onClick={() => {navigator.clipboard.writeText(polarData)}}>Copy</Button>
                        }
                    />
                    <CardContent style={{width: "100%"}}>
                        <pre>{polarData}</pre>
                    </CardContent>
                </Card>
            }
        </Container>
    );
};
export default CogniteSessionResultsTable;