import englishMessages from 'ra-language-english';

export const messages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        switchLang: 'Switch Language',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        changePassword: {
            label: 'Change password',
            oldPw: 'Current password',
            newPw: 'New password', 
            newPwConfirm: 'Confirm new password',
            confirm: 'Submit',
            newPwShort: 'Password is too short',
            newPwNoUpper: 'At least one uppercase character is needed',
            newPwNoNumber: 'At least one number is needed',
            newPwMatchError: 'Passwords do not match',
            newPwEmpty: 'This field cannot be empty'
        },
    },

    resources: {
        specialists: {
            name: 'Specialists',
            fields: {
                userName: "User name",
                email: "Email",
                name: "Name",
                lastName: "Last name",
                position: "Position",
                contacts: "Contacts"
            },
            customActions: {
                resetPassword: 'Reset specialist password'
            },
            tabs: {
                assignedPatients: "Assigned clients",
                departments: "Departments",
            }
        },
        sessions: {
            no_charts: 'No charts available.',
            name: "Sessions",
            fields:
            {
                startTime: 'Start time',
                endTime: 'End time',
                startDate: 'Date',
                notes: 'Notes',
                score: 'Score',
                patientUsername: 'Client',
                sessionType: 'Type',
                moduleId: 'Module',
                sessionShortId: 'ID'
            }
        },
        devices: {
            name: "Devices",
            fields:
            {
                name: 'Name',
                moduleId: 'Module',
                macAdress: 'MAC adress',
                serialAdress: 'Serial adress'
            }
        },
        patients: {
            name: "Clients",
            fields:
            {
                id: 'Client ID',
                userName: 'Code',
                currentDepartmentId: 'Department',
                contacts: 'Contacts',
                headWidth: 'Head width (mm)',
                headLength: 'Head length (mm)'
            },
            patientModules: "Related modules"
        },
        departments: {
            name: "Departments",
            fields:
            {
                name: 'Name',
                isMain: 'Main department',
                adminUsername: 'Administrator'
            },
            customActions: {
                resetPassword: 'Reset admin password'
            }
        },
        disabilities: {
            name: "Disabilities",
            fields:
            {
                name: 'Name',
                description: 'Description',
            },
            disabilityModules: 'Related modules'
        },
        modules: {
            name: "Modules",
            parameters: {
                saveBtn: 'Save'
            },
            fields:
            {
                name: 'Name',
                description: 'Description',
                assigned: "Assigned"
            },
        },
        relocationrequests: {
            name: "Relocations",
            namepending: "Pending relocation requests",
            namemy: "My relocation requests",
            fields:
            {
                relocationRequestId: "Relocation ID",
                patientUsername: 'Client code',
                accepted: 'Accepted',
                requestTime: 'Request time',
                responseTime: 'Response time',
                oldDepartmentId: 'Old department',
                newDepartmentId: 'New department'
            },
            customActions: {
                relocate: 'Relocate',
                reject: 'Reject',
            }
        },
        cogniteGameResults: {
            name: "Task summary",
            fields: {
                // Attention transfer test
                gameMinute: "Game minute",
                correctAnswers: "Correct answers",
                mistakenAnswers: "Mistaken answers",
                totalAnswers: "Total Answers",
                successPercent: "Success %",
            },
        },
        cogniteresults: {
            name: "Task results",
            fields:
            {
                taskNo: '#',
                correct: "Correct?",

                // Anticipation test
                points: "Game Score",
                points_x: "Points for x axis",
                points_y: "Points for y axis",
                xoffset: "X Offset",
                yoffset: "Y Offset",
                

                // Attention transfer test
                currentDifficulty: 'Current difficulty level',
                currentGameTime: 'Game time in seconds',
                GAME_TYPE: 'Game Mode',
                reactionTime: 'Reaction time in milliseconds',
                selectedCard: 'Selected number',
                startingNumber: 'Starting number',
                successfulMoves: 'Total successful moves',

                // Predict test
                afterfake: 'Was stimulus too fast?',
                difficultyState: 'Difficulty level',
                mistakeafter: 'Mistakes made after',
                time: "Time"
            },
        },
        hrvAnalysis: {
            name: "HRV Analysis",
            fields: {
                SDNN: "SDNN",
                SDSD: "SDSD",
                RMSSD: "RMSSD",
                NN50: "NN50",
                pNN50: "pNN50",
            }
        }
    },

    notifications: {
        themeChange: 'Theme has been changed to %{theme}!',
        localeChange: 'Locale has been changed to %{locale}!',
    },

    enhancedTable: { 
        error: "Data could not be retrieved",
        grouping: {
            groupedBy: "Grouped By:",
            placeholder: "Drag headers here to group by",
        },
        pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
            labelRowsPerPage: "Rows per page:",
            labelRowsSelect: "rows",
            firstAriaLabel: 'First Page',
            firstTooltip: 'First page',
            previousAriaLabel: 'Previous Page',
            previousTooltip: 'Previous Page',
            nextAriaLabel: 'Next page',
            nextTooltip: 'Next page',
            lastAriaLabel: 'Last Page',
            lastTooltip: 'Last Page'
        },
        toolbar: {
            addRemoveColumns: 'Add or delete columns',
            nRowsSelected: '{0} row (s) selected',
            showColumnsTitle: 'Show columns',
            showColumnsAriaLabel: 'Show columns',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',
            exportName: 'Export as CSV',
            exportCSVName: "Export as CSV",
            exportPDFName: "Export as PDF",
            searchTooltip: 'Search',
            searchPlaceholder: 'Client code',
            refreshData: 'Refresh Data',
            toggleFiltering: 'Toggle Filtering',
        },
        header: {
            actions: 'Actions',
        },
        body: {
            filterRow: {
                filterTooltip: 'Filter'
            },
            editRow: {
                saveTooltip: "Save",
                cancelTooltip: "Cancel",
                deleteText: "Are you sure you want to delete this row?",
            },
            addTooltip: "Add",
            deleteTooltip: "Delete",
            editTooltip: "Edit",
            bulkEditTooltip: "Edit All",
            bulkEditApprove: "Save all changes",
            bulkEditCancel: "Discard all changes",
            emptyDataSourceMessage: 'No entries',
        },
    }

};

export default messages;
