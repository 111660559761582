import React from 'react';
import Chart from "react-apexcharts";

const columnChart = {
  chart: {
      type: "bar"
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    tickAmount: 'dataPoints',
    axisTicks: {
        show: false,
    },
  },
  yaxis: {
    tickAmount: 7,
    decimalsInFloat: true,
  },
  title: {
      text: 'Total Space',
      align: 'left'
  }
};

const SpaceColumnChart = ({ titleOptions, tableData }) => {
    const spaceChartSeries = tableData.reduce((acc, data) => {
        const taskNo = parseInt(data.taskNo);
        const yoffset = parseFloat(data.yoffset);
        acc.push({ x: taskNo, y: yoffset });
        return acc;
    }, []);

    return (
        <Chart
            options={{...columnChart, ...titleOptions }}
            series={[{
                data: spaceChartSeries
            }]}
            type={columnChart.chart.type}
            width="100%"
            height="200px"
        />
    );
};

export default SpaceColumnChart;