import reduxModules from '../redux-modules';
import { useSelector } from 'react-redux';
import { showNotification } from 'react-admin';

const getFilters = query => {
    let filters = {};
    for (let filter of query.filters) {
        let column = filter.column;
        let filterValue = column.tableData.filterValue;
        if (column.type == "boolean") {
            if (filterValue == "checked") filterValue = true;
            else if (filterValue == "unchecked") filterValue = false;
        }
        else if (column.lookupTable) { //Ignore lookup filter if it exists but there's nothing checked
            filterValue = filterValue.length ? filterValue : undefined;
        }
        filters[capitalizeFirstLetter(column.field)] = filterValue;
    }
    return filters;
};

const replaceColumns = (
    { i18nKey, translateFn, columnArray },
) => {
    let retranslationRequired = false;

    return columnArray
        .map(Column => {
            if (Column) {
                if (!retranslationRequired && Column.title) {
                    const currentLocaleTitle = translateFn(`resources.${i18nKey}.fields.${Column.customTitle || Column.field}`);
                    if (Column.title !== currentLocaleTitle) {
                        retranslationRequired = true;
                    }
                }

                if ((!Column.title || retranslationRequired) && !Column.hidden) {
                    Column.title = translateFn(`resources.${i18nKey}.fields.${Column.customTitle || Column.field}`);
                }
                if (Column.lookupTable) {
                    Column.lookup = useSelector(state => state.lookupReducer[Column.lookupTable]);
                }
            }
            return Column;
        })
        .filter(Boolean);
};

const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  }

const fetchColumns = (
    query,
    title,
    tableName,
    dataProvider,
    dispatch,
    override_filters = {},
    customURLParams = {},
    columns
) => {
    //If filtering was disabled by user, clear all filter values
    if (query.filtering === false) {
        query.filters = [];
        columns = columns.map(column => { column.tableData.filterValue = undefined; return column; });
    }

    let filters = {
        ...getFilters(query),
        ...override_filters,
    };

    return new Promise((resolve, reject) => {
        return dataProvider
            .getList(tableName + '/list',
                {
                    orderBy: query.orderBy && capitalizeFirstLetter(query.orderBy.field),
                    orderDirection: query.orderBy && query.orderDirection,
                    page: query.page + 1,
                    itemsPerPage: query.pageSize,
                    filterValues: JSON.stringify(filters),
                    ...customURLParams
                })
            .then(json => {
                dispatch(
                    reduxModules.refreshTable({
                        [title]: {
                            pageSize: query.pageSize,
                            columns: columns,
                            //during the first load query.filtering is undefined, so we also check for saved filter values
                            filtering: query.filtering || Boolean(query.filters.length)
                        },
                    })
                );

                return resolve({
                    data: json.data.items,
                    totalCount: parseInt(json.data.totalCount),
                    page: parseInt(query.page),
                });
            })
            .catch(error => {
                dispatch(
                    showNotification('Failed to fetch data', 'error', {
                        autoHideDuration: 5000
                    }));
                reject(error);
            });
        }
    );
    };

export { replaceColumns, fetchColumns };
