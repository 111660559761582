import React from 'react';
import Chart from "react-apexcharts";

const rrIntervalChart = {
    chart: {
        id: "rrIntervalChart",
        type: "area",
        height: 230,
        foreColor: "#000",
        toolbar: {
            autoSelected: "pan",
            show: true
        },
        animations: {
            enabled: false
        }
    },
    colors: ["#00BAEC"],
    stroke: {
        width: 3
    },
    grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
            lines: {
                show: false
            }
        }
    },
    dataLabels: {
        enabled: false
    },
    fill: {
        gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0
        }
    },
    markers: {
        size: 0,
        colors: ["#000524"],
        strokeColor: "#00BAEC",
        strokeWidth: 3
    },
    tooltip: {
        theme: "dark"
    },
    xaxis: {
        type: "numeric",
        min: 0
    },
    yaxis: {
        min: 0,
        max: 1.5,
        tickAmount: 4,
        forceNiceScale: true,
    },
    title: {
        text: 'R-R Interval Chart',
        align: 'left'
    }
};

const RRIntervalSeriesChart = ({ polarData }) => {
    const rrIntervalSeries = polarData.rRintervals.reduce((acc, interval) => {
        const rrInterval = parseInt(interval);
        acc.push([acc[acc.length - 1][0] + rrInterval, rrInterval / 1000]);
        return acc;
    }, [[0, 0]]).splice(1);

    if (rrIntervalSeries.length == 0)
        return null;

    return (
        <Chart
            options={rrIntervalChart}
            series={[{
                name: "R-R Interval",
                data: rrIntervalSeries
            }]}
            type={rrIntervalChart.chart.type}
            width="100%"
            height="200px"
        />
    );
};

export default RRIntervalSeriesChart;