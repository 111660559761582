import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector } from 'react-redux';
import reduxModules from '../redux-modules';

const AssignedCheckbox = ({
    dataProvider,
    dispatch,
    childId,
    endpoint,
    parentIdField,
    childIdField,
    ...props
}) => {
    const assigned = useSelector(state => state.assignedReducer[endpoint]);

    const updateState = (newAssignedRows) => dispatch(
        reduxModules.updateAssigned({
            [endpoint]:                         
                {
                    parentId: assigned.parentId,
                    assignedRows: newAssignedRows
                }
        })
    );

    const onAssignedChange = (event) =>  {
        event.preventDefault();
        let newAssignedRows = assigned.assignedRows;
        if (event.target.checked) {
            dataProvider.create(endpoint, {
                data: {
                    [parentIdField]: assigned.parentId,
                    [childIdField]: childId
                },
            })
            .then(json => {
                //Add to state array
                newAssignedRows.push(childId)
                updateState(newAssignedRows);
            });   
        }
        else {
            dataProvider.delete(endpoint, {
                data: {
                    [parentIdField]: assigned.parentId,
                    [childIdField]: childId
                },
            })
            .then(json => {
                //Remove from state array
                const index = newAssignedRows.indexOf(childId);
                if (index > -1) {
                    newAssignedRows.splice(index, 1);
                }
                updateState(newAssignedRows);
            });
        }
    }

    return assigned ? <Checkbox style={{ margin: "-8px" }} checked={assigned.assignedRows.includes(childId)} onChange={e => onAssignedChange(e)} {...props}/> : null
};

export default AssignedCheckbox;