import { useMemo } from 'react';
import reduxModules from './redux-modules';

const createDataExtension = (dataProvider, dispatch, lookupTable, lookupIdField, lookupField) => {
    const fetchLookupNames = () =>
        dataProvider.getList(lookupTable).then(({ data: lookupData }) => {
            let table = {};
            lookupData.forEach(v => {
                table[v[lookupIdField]] = v[lookupField];
            });
            dispatch(reduxModules.lookupAction({[lookupTable] : table }));
            return table;
        });

    return {
        fetchLookupNames,
        useLookupNames: dependencies => useMemo(fetchLookupNames, [JSON.stringify(dependencies)]),
    };
};

export default createDataExtension;
