import React from 'react';
import { useDataProvider, Title } from 'react-admin';
import { MTableBodyRow, MTableToolbar } from 'material-table';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import SessionModal from './SessionModal';
// import ModuleFilter from './SessionFilter';
//import SessionDatePicker from './SessionDatePicker';
import authProvider from '../authProvider';
import createEditableRowOps from '../editableRowOps';
import createDataExtension from '../dataExtension';

const CustomToolbarComponent = (dispatch, selectedDate, customFilters) => props => (
    <div style={{ display: 'inline-flex', width: "100%" }}>
        <div style={{ width: 'inherit' }}><MTableToolbar {...props} /></div>
        <div style={{ display: 'contents' }}>
            {/* <SessionDatePicker {...{ dispatch, selectedDate }} /> */}
            {/* {props.data.length ? <ModuleFilter selectedModule={customFilters.moduleId} {...{ dispatch, ...props }} /> : null } */}
        </div>
    </div>
);

const CustomRowComponent = (dataProvider, modulesLookupTable) => props => {
    if (!props.data.togglePanel) {
        props.data.togglePanel = function() {    
            if (!props.data.sessionData) {
                dataProvider
                    .getOne(`sessions`, { id: props.data.sessionId })
                    .then(response => {
                        props.data.sessionData = { ...response.data, module: modulesLookupTable[response.data.moduleId] };
                        props.onToggleDetailPanel(props.path, props.detailPanel);
                    });
            } else {
                props.onToggleDetailPanel(props.path, props.detailPanel);
            }
        };
    }
    return <MTableBodyRow {...{...props, onToggleDetailPanel: props.data.togglePanel}}/>;
};

const SessionList = props => {
    const endpoint = 'sessions';
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    //const sessionState = useSelector(state => state.sessionReducer);

    //const customFilters = sessionState.customFilters || {};
    
    //const selectedDate = sessionState.selectedDate || new Date().toISOString().split('T')[0];

    //const CustomToolbar = CustomToolbarComponent(dispatch, selectedDate, customFilters);
    const permissions = authProvider.getPermissionsByResource(endpoint);
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);

    const dataExtension = createDataExtension(dataProvider, dispatch, 'modules', 'moduleId', 'name');
    const modulesLookupTable = useSelector(state => state.lookupReducer.modules);
    
    dataExtension.useLookupNames(modulesLookupTable);

    const CustomRow = CustomRowComponent(dataProvider, modulesLookupTable);

    return (
        <div className="ListLayoutFullWidth">
            <Title title='resources.sessions.name' />
            <EnhancedTable
                {...{
                    title: 'resources.sessions.name',
                    tableName: endpoint,
                    //customURLParams: { dateFrom: selectedDate, dateTo: selectedDate },
                    //customFilters: customFilters,
                   // key: Object.keys(customFilters).length + selectedDate, // to force rerender if custom filters were selected.
                    columns: [
                        { field: 'sessionId', hidden: true },
                        { field: 'sessionShortId', type: 'numeric' },
                        { field: 'patientUsername', editable: 'never' },
                        { field: 'score', type: 'numeric' },
                        { field: 'startTime', type: 'date', customTitle: 'startDate',  dateSetting: { locale: 'lt'}, defaultSort: 'desc' },
                        { field: 'startTime', type: 'time', dateSetting: { locale: 'lt'}, filtering: false },
                        { field: 'endTime', type: 'datetime', dateSetting: { locale: 'lt'} },
                        { field: 'notes' },
                        { field: 'moduleId', lookupTable: 'modules', editable: 'never' }
                    ],
                    components: {
                        //Toolbar: CustomToolbar,
                        Row: CustomRow,
                    },
                    detailPanel: (rowData) => <SessionModal {...rowData} />,
                    onRowClick: (event, rowData) => rowData.togglePanel(),
                    customOptions: {
                        exportButton: true,
                        exportAllData: true
                    },
                    editable: EditableRowOps([endpoint, 'sessionId', permissions])
                }}
            />
        </div>
    );
};

export default SessionList;
