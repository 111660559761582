import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

const AutoFocusSearchField = props => (
    <TextField
        autoFocus={props.autoFocus}
        value={props.searchText}
        onChange={event => props.onSearchChanged(event.target.value)}
        placeholder={props.localization.searchPlaceholder}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <Tooltip title={props.localization.searchTooltip}>
                        <props.icons.Search color="inherit" fontSize="small" />
                    </Tooltip>
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton disabled={!props.searchText} onClick={() => props.onSearchChanged('')}>
                        <props.icons.ResetSearch color="inherit" fontSize="small" />
                    </IconButton>
                </InputAdornment>
            ),
            style: props.searchFieldStyle,
        }}
    />
);

export default AutoFocusSearchField;
