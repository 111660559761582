import React from 'react';
import { Admin, Resource } from 'react-admin';
import { render } from 'react-dom';

import './main.css';

import authProvider from './authProvider';
import prodDataProvider from './prodDataProvider';
import i18nProvider from './i18nProvider';
import Layout from './layout/Layout';
import patients from './patients';
import relocations from './relocations';
import specialists from './specialists';
import modules from './modules';
import devices from './devices';
import disabilities from './illness_types';
import departments from './departments';
import rehab_sessions from './rehab_sessions';
import reduxModules from './redux-modules';
import customRoutes from './routes';

const initialState = () => ({
    themeReducer: localStorage.getItem('theme') || 'light',
});

render(
    <Admin
        title=""
        layout={Layout}
        authProvider={authProvider}
        dataProvider={prodDataProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        customSagas={reduxModules.sagas}
        customReducers={reduxModules.reducers}
        initialState={initialState}
    >
        {() => [
            authProvider.getPermissionsByResource("sessions").read && <Resource name="sessions" {...rehab_sessions} />,
            authProvider.getPermissionsByResource("relocationrequests").read && <Resource name="relocationrequests" {...relocations} />,
            authProvider.getPermissionsByResource("specialists").read && <Resource name="specialists" {...specialists} />,
            authProvider.getPermissionsByResource("patients").read && <Resource name="patients" {...patients} />,
            authProvider.getPermissionsByResource("departments").read && <Resource name="departments" {...departments} />,
            authProvider.getPermissionsByResource("disabilities").read && <Resource name="disabilities" {...disabilities} />,
            authProvider.getPermissionsByResource("modules").read && <Resource name="modules" {...modules} />,
            authProvider.getPermissionsByResource("devices").read && <Resource name="devices" {...devices} />,
        ]}
    </Admin>,
    document.getElementById('root')
);
