import React, { useCallback, useRef } from 'react';
import { Title, useDataProvider, showNotification, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { MTableToolbar } from 'material-table';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import AutoFocusSearchField from './AutoFocusSearchField';
import createDataExtension from '../dataExtension';
import reduxModules from '../redux-modules';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';

const CustomToolbarComponent = (tableRef, onSearchChangeCallback, clinicCode) => props => (
    <div style={{ display: 'inline-flex', width: '100%' }}>
        <div style={{ width: 'inherit' }}>
            <MTableToolbar {...props} />
        </div>
        <div style={{ display: 'contents' }}>
            <AutoFocusSearchField
                {...{
                    ...props,
                    onSearchChanged: onSearchChangeCallback,
                    autoFocus: tableRef.current && tableRef.current.props.options.autoFocus,
                    searchText: props.searchText || clinicCode,
                }}
            />
        </div>
    </div>
);

const onSearchChanged = (() => {
    let debouncerId = null;

    const storeFn = function(dispatch, searchText) {
        dispatch(
            reduxModules.storeSessionData({
                customFilters: { PatientUsername: searchText },
            })
        );
    };

    return function(tableRef, dispatch, searchText) {
        tableRef.dataManager.changeSearchText(searchText);
        tableRef.setState({ searchText }, function() {
            clearTimeout(debouncerId);
            if (this.state.searchText === '') {
               storeFn(dispatch, this.state.searchText);
            } else {
                debouncerId = setTimeout(() => {
                    storeFn(dispatch, this.state.searchText);
                }, tableRef.props.options.debounceInterval);
            }
        });
    };
})();

const RelocationsList = props => {
    const endpoint = 'relocationrequests';
    const translate = useTranslate();
    const tableRef = useRef();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const sessionState = useSelector(state => state.sessionReducer);

    const dataExtension = createDataExtension(dataProvider, dispatch, 'departments', 'departmentId', 'name');
    const departmentsLookupTable = useSelector(state => state.lookupReducer.departments);
    dataExtension.useLookupNames(departmentsLookupTable);

    const customFilters = sessionState.customFilters || {};
    const clinicCode = customFilters.PatientUsername || '';
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    const onSearchChangeCallback = useCallback(
        (searchText) => {
            onSearchChanged(tableRef.current, dispatch, searchText);
        },
        [!!tableRef.current]
    );

    const handleRelocationAction = (e, rowData, accepted) => {    
        e.preventDefault();  
        dataProvider.updatePartial(endpoint, 
        {
            id: rowData.relocationRequestId, 
            data: accepted
        }).then(() => {
            tableRef.current && tableRef.current.onQueryChange(); //Refresh
        });  
    }

    const CustomToolbar = CustomToolbarComponent(tableRef, onSearchChangeCallback, clinicCode);

    return (
        <div className="ListLayoutFullWidth">
            <Title title="resources.relocationrequests.name" />
            <EnhancedTable
                {...{
                    title: 'resources.relocationrequests.namepending',
                    tableName: endpoint,
                    customFilters: customFilters,
                    key: clinicCode,
                    columns: [
                        { field: 'relocationRequestId', hidden: true },
                        { field: 'newDepartmentId', lookupTable: 'departments' },
                        { field: 'patientUsername', filtering: false }
                    ],
                    outerRef: tableRef,
                    components: {
                        Toolbar: CustomToolbar,
                    },
                    customActions: [
                        {
                            icon: 'done',
                            tooltip: translate('resources.relocationrequests.customActions.relocate'),
                            onClick: (event, rowData) => handleRelocationAction(event, rowData, true)
                        },
                        {
                            icon: 'clear',
                            tooltip: translate('resources.relocationrequests.customActions.reject'),
                            onClick: (event, rowData) => handleRelocationAction(event, rowData, false)
                        },
                    ],
                    customURLParams: {confirmed: false},
                    customOptions: {
                        search: false,
                        autoFocus: true,
                        exportButton: false,
                        debounceInterval: 500,
                    },
                }}
            />

            <EnhancedTable
                style={{ position: 'relative', top: '2%' }}
                {...{
                    title: 'resources.relocationrequests.namemy',
                    tableName: endpoint,
                    columns: [
                        { field: 'relocationRequestId', hidden: true },
                        { field: 'oldDepartmentId', lookupTable: 'departments', editable: 'never' },
                        { field: 'patientUsername' },
                        { field: 'accepted', type: 'boolean', editable: 'never' },
                        { field: 'requestTime', editable: 'never', type: 'datetime', dateSetting: { locale: 'lt'} },
                        { field: 'responseTime', editable: 'never', type: 'datetime', dateSetting: { locale: 'lt'} }
                    ],

                    customURLParams: {myDepartmentRequests: true},
                    customOptions: {
                        search: false,
                        exportButton: false,
                    },

                    editable: {
                        ...EditableRowOps([endpoint, 'relocationRequestId', permissions]), 
                        isDeletable: rowData => rowData.accepted == null,
                        onRowAdd: permissions.create ?  
                        (newData => {
                            return new Promise((resolve, reject) => {
                                dataProvider
                                    .create(endpoint + '?patientusername=' + newData.patientUsername, {
                                        data: null
                                    })
                                    .then(() => { resolve(); })
                                    .catch((error) => {
                                        if (error.info.status == 404)
                                            dispatch(
                                                showNotification('notifications.patientnotfound', 'error', {
                                                    autoHideDuration: 5000
                                            }));
                                        reject();
                                    })
                        })}) : null
                    }
                }}
            />
        </div>
    );
};

export default RelocationsList;
