import React from 'react';
import { useTranslate } from 'react-admin';
import { Container, Card, CardHeader, CardContent } from '@material-ui/core';
import RRIntervalSeriesChart from './Charts/RRIntervalSeriesChart';
import ReactionBoxPlotChart from './Charts/ReactionBoxPlotChart';
import ReactionSeriesChart from './Charts/ReactionSeriesChart';
import HeartRateSeriesChart from './Charts/HeartRateSeriesChart';
import MultiLevelSpaceColumnAndBoxPlotCharts from './Charts/MultiLevelSpaceColumnAndBoxPlotCharts';
import MultiLevelTimeColumnAndBoxPlotCharts from './Charts/MultiLevelTimeColumnAndBoxPlotCharts';

// https://apexcharts.com/docs/chart-types/area-chart/

const SessionCharts = (props) => {
    const translate = useTranslate();

    const Charts = [
        <RRIntervalSeriesChart polarData={props.polarData} />, 
        <HeartRateSeriesChart polarData={props.polarData} />,
        props.moduleId === "4" && <ReactionBoxPlotChart tableData={props.tableData} />,
        props.moduleId === "2" && <ReactionSeriesChart tableData={props.tableData} />, 
        props.moduleId === "3" && <MultiLevelSpaceColumnAndBoxPlotCharts tableData={props.tableData} />,
        props.moduleId === "3" && <MultiLevelTimeColumnAndBoxPlotCharts tableData={props.tableData} />
    ].filter(Boolean);

    return (
        <Container className="ListLayoutFullWidth" disableGutters={true}>
            <Card style={{ marginTop: "10px" }}>
                <CardHeader title="Charts" style={{backgroundColor: "#f9f9f9"}} />
                <CardContent>
                    { Charts.some(chart => chart.type(chart.props) !== null) ? Charts : <center>{translate("resources.sessions.no_charts")}</center> }
                </CardContent>
            </Card>
        </Container>
    );
};
export default SessionCharts;