import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import * as localeMessages from './i18n';

const browserLocale = resolveBrowserLocale();

const messages = {
    lt: () => import('./i18n/lt.js').then(messages => messages.default)
};

export default polyglotI18nProvider(locale => {
    if (messages[locale]) {
        return messages[locale]();
    }

    // Always fallback on english
    return localeMessages[localStorage.getItem('locale') || "en"];
}, "en");
