import React from 'react';
import Chart from "react-apexcharts";

const reactionChart = {
    chart: {
        height: 350,
        type: 'scatter',
        zoom: {
            enabled: true,
            type: 'xy'
        }
    },
    colors: ['#000000', '#FFFFFF'],
    markers: {
        size: 5,
        colors: ['#000000', '#FFFFFF'],
        strokeColor: "#000000",
        strokeWidth: 2,
    },
    legend: {
        markers: {
            width: 6,
            height: 6,
            strokeWidth: 1,
            strokeColor: '#000',
        },
    },
    xaxis: {
        tickAmount: 10,
    },
    yaxis: {
        tickAmount: 7
    },
    title: {
        text: 'Reaction Time Chart',
        align: 'left'
    }
};

const ReactionSeriesChart = ({ tableData }) => {
    const reactionSeries = tableData.reduce((acc, item) => {
        const taskNo = parseInt(item.taskNo);
        const time = parseFloat(item.time);
        const currentAnswer = item.correct === "true" ? 1 : 0;
        acc[currentAnswer].push([taskNo, time]);
        return acc;
    }, [[], []]);

    if (reactionSeries.length == 0)
        return null;

    return (
        <Chart
            options={reactionChart}
            series={[{
                name: "Correct",
                data: reactionSeries[1]
            }, {
                name: "Incorrect",
                data: reactionSeries[0]
            }]}
            type={reactionChart.chart.type}
            width="100%"
            height="300px"
        /> 
    );
};

export default ReactionSeriesChart;