import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { Button, LinearProgress } from '@material-ui/core';

const intervalLimitMs = 100;
const progressIntervalMs = 250; //For progress bar

const SessionVREmbed = (props) => {
    const [progress, setProgress] = useState(0);
    const [playing, setPlaying] = useState(false);
    const commands = props.commands;

    const normalize = value => value * 100 / maxProgress;

    const divId = "pano-" + props.sessionId;
    
    const commandCount = useMemo(() => commands.length, [commands]);
    const maxProgress = useMemo(() => commands[commandCount - 1].time, [commands, commandCount]);

    const i = useRef(0);
    const VRProgress = useRef(0);
    const lastTimestamp = useRef(0);

    const animationRef = useRef();
    const krpanoRef = useRef();
    
    useEffect(() => {
        const embedId = "embed-" + props.sessionId;
        const vrPath = () => {
            switch (props.module) {
                case "VR shop game":
                    return "maxima_app";
                case "Memory card game":
                    return "memorycards_app";
                default:
                    return "paircards_app";
            }
        };
        window.embedpano({ swf: "tour.swf", xml: `tour/${vrPath()}/main.xml`, target: divId, html5: "auto", mobilescale: 1.0, passQueryParameters: true, id: embedId });
        krpanoRef.current = document.getElementById(embedId);
    }, [props.module, divId, props.sessionId]);


    function playbackLoop(timestamp) {
        if (i.current < commandCount) {
            while (i.current < commandCount && commands[i.current].time < VRProgress.current) {
                let command = commands[i.current].actions;
                // if (command.includes("lookto")) {
                // command = command.substring(0, command.length - 2);
                // command += ",undefined,undefined,undefined,true);" //doesn't block anymore, but the camera doesn't move at all, no idea

                // }
                command = command.replace("lookto", "lookat");
                krpanoRef.current.call(command);
                i.current++;
            }
            if (timestamp - lastTimestamp.current < intervalLimitMs) { //Prevents firing of many krpano commands if the window was minimized
                VRProgress.current += timestamp - lastTimestamp.current;
            }

            //Progress bar doesn't have to update as often
            setProgress((oldProgress) => {
                if (VRProgress.current - oldProgress > progressIntervalMs)
                    return VRProgress.current;
                else
                    return oldProgress;
            });
        } else {
            setProgress(maxProgress);
            setPlaying(false);
        }

        lastTimestamp.current = timestamp;
        animationRef.current = requestAnimationFrame(playbackLoop);
    }

    useLayoutEffect(() => {
        //setInterval is slow with 30/60 fps on Firefox, so we are using requestAnimationFrame
        //This gives better performance but it halts execution when the tab is not visible
        //Krpano is unpredictable when minimized anyway so this isn't really a drawback
        if (playing) {
            animationRef.current = requestAnimationFrame(playbackLoop);
            lastTimestamp.current = performance.now();
        }
           
        return () => { cancelAnimationFrame(animationRef.current) }
    }, [playing]);

    const resetPlayer = () => {
        //TODO: Send a command to krpano to reset scene?
        setPlaying(0);
        setProgress(0);
        i.current = 0;
        VRProgress.current = 0;
    };        

    return (
        <div>
            <div id={divId} style={{ width: '30em', height: '25em' }}></div>
            <Button
                variant="contained"
                onClick={() => setPlaying(true)}
                style={{ margin: '1em' }}
            >
                Play
            </Button>
            <Button
                variant="contained"
                onClick={() => setPlaying(false)}
                style={{ margin: '1em' }}
            >
                Pause
            </Button>
            <Button
                variant="contained"
                onClick={() => { resetPlayer() }}
                style={{ margin: '1em' }}
            >
                Reset
            </Button>
            <LinearProgress variant="determinate" value={normalize(progress)} style={{ width: '100%' }} />
        </div>
    );
}

export default SessionVREmbed;