import React, { forwardRef } from 'react';
import { Layout, AppBar, UserMenu, useTranslate, MenuItemLink } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
}));

const ConfigurationMenu = forwardRef((_, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
        />
    );
});

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu/>}/>;

export default props => {
    const theme = useSelector(state => state.themeReducer);

    return (
        <Layout
            {...props}
            appBar={MyAppBar}
            theme={theme === 'light' ? lightTheme : darkTheme}
            key={theme}
        />
    );
};
