import { CHANGE_THEME } from '../constants';
import { put, takeEvery } from 'redux-saga/effects';
import { showNotification, hideNotification } from 'react-admin';

export default function* themeSaga() {
    yield takeEvery(CHANGE_THEME, function*({ payload }) {
        localStorage.setItem('theme', payload);
        yield put(hideNotification());
        yield put(
            showNotification('notifications.themeChange', 'info', {
                autoHideDuration: 2000,
                messageArgs: { theme: payload.toUpperCase() },
            })
        );
    });
}
