import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import CustomRouteLayout from './customRouteLayout';
import CustomRouteNoLayout from './customRouteNoLayout';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/custom" component={props => <CustomRouteNoLayout {...props} />} noLayout />,
    <Route exact path="/custom2" component={props => <CustomRouteLayout {...props} />} />,
];
