export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiTabs: {
            root: {
                color: 'white',
            },
        },
        MuiFormControl: {
            root: {
                margin: 'auto',
            },
        },
        MuiTableCell: {
            head: {
                borderRight: '1px solid rgba(81, 81, 81, 1)',
                borderTop: '1px solid rgba(81, 81, 81, 1)',
            },
            body: {
                borderRight: '1px solid rgba(81, 81, 81, 1)',
            },
        },
    },
};

export const lightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#f9f9f9',
            dark: '#001064',
            contrastText: '#ad3030',
        },
    },
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                "&$checked": 'unset'
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#ad3030',
            },
            textSecondary: {
                color: 'inherit',
            },
        },
        MuiFormControl: {
            root: {
                margin: 'auto',
            },
        },
        MuiTableCell: {
            head: {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
            body: {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
            paddingCheckbox: {
                padding: '11px'
            },
            paddingNone: {
                padding: '5px',
                '&:last-child': {
                    borderBottom: 'none',
                },
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};
