import React from 'react';
import EnhancedTable from "../enhancedTable/EnhancedTable";

const HRVAnalysis = ({ hrvAnalysis }) => {
    return (
        Math.max(...Object.values(hrvAnalysis)) > 0 && <EnhancedTable
            style={{ marginTop: "10px" }}
            {...{
                title: 'resources.hrvAnalysis.name',
                tableName: "hrvAnalysis",
                customData: [Object.fromEntries(Object.entries(hrvAnalysis).map(([k, v]) => [k, v.toFixed(2)]))],
                columns: [
                    { field: 'SDNN', type: 'numeric' },
                    { field: 'SDSD', type: 'numeric' },
                    { field: 'RMSSD', type: 'numeric' },
                    { field: 'NN50', type: 'numeric' },
                    { field: 'pNN50', type: 'numeric' },
                ],
                editable: false,
                customOptions: {
                    paging: false
                },
            }}
        />
    );
};

export default HRVAnalysis;
