import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useTranslate, showNotification } from 'react-admin';
import { Container, Card, CardContent, CardActions, Button, TextField, makeStyles } from '@material-ui/core';
import reduxModules from '../redux-modules';

const useStyles = makeStyles({
    unavailableContainer: { textAlign: 'center' },
    container: { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' },
    button: { display: 'block', margin: '0px auto', width: '200px' },
});

const useModuleParams = (endpoint) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList(`moduleParameters${endpoint}`).then((response) => {
            const mappedResponse = Object.entries(response.data).map((param) => ({ code: param[0], value: param[1] }));
            dispatch(reduxModules.storeModuleParameterData({ [endpoint]: mappedResponse }));
        });
    }, [endpoint]);
};

const useSaveHandler = (patientId) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    return useCallback((moduleId, params) => {
        dataProvider.create(`moduleParameters${patientId ? '/byPatient' : ''}`, {
            data: {
                ...(patientId && { patientId }),
                moduleId,
                params: params.reduce((mappedParams, param) => {
                    mappedParams[param.code] = param.value;
                    return mappedParams;
                }, {})
            }
        }).then((response) => {
            if (response.data) {
                dispatch(showNotification("Successfully updated data.", 'success', { autoHideDuration: 3000 }));
            }
        });   
    });
};

const ModuleParameterField = ({ param }) => {
    const [currentParam, updateParameter] = useState(param);

    return <TextField
        label={currentParam.code} 
        value={currentParam.value} 
        onChange={(e) => {
            param.value = e.target.value;
            updateParameter({ code: currentParam.code, value: param.value });
        }}
    />;
}; 

const ModuleParamList = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    
    const assignedPatient = useSelector(state => state.assignedReducer['patientModules']);
    const saveHandler = useSaveHandler(assignedPatient?.parentId);

    const currentModuleId = `moduleId=${props.moduleId}`;
    const moduleParamStoreEndpoint = assignedPatient ? `/byPatient?patientId=${assignedPatient.parentId}&${currentModuleId}` : `?${currentModuleId}`;
    
    useModuleParams(moduleParamStoreEndpoint);

    const moduleParams = useSelector(state => state.moduleParamReducer[moduleParamStoreEndpoint]);

    const unavailableContent = <Container className={classes.unavailableContainer}>{ (moduleParams == null ? 'Fetching parameters from server ...' : 'No parameters available.') }</Container>;
    const renderContent = moduleParams?.length ? moduleParams.map((param, idx) => <ModuleParameterField key={idx + param.code + param.value + assignedPatient?.parentId} param={param} />) : unavailableContent;

    return (
        <Card elevation={2} square={true} raised={true}>
            <CardContent className={classes.container}>
                { renderContent }
            </CardContent>
            <CardActions>
                <Button className={classes.button} variant="contained" onClick={() => saveHandler(props.moduleId, moduleParams)}>{translate('resources.modules.parameters.saveBtn')}</Button>
            </CardActions>
        </Card>
    );
}

export default ModuleParamList;

