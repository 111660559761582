import React from 'react';
import { useDataProvider, Title } from 'react-admin';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';
import { useDispatch } from 'react-redux';
import ModuleParamList from './ModuleParamList';

const ModuleList = (props) => {
    const endpoint = 'modules';
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    return (
        <div className="ListLayoutFullWidth">
            <Title title="resources.modules.name" />
            <EnhancedTable
                {...{
                    title: 'resources.modules.name',
                    tableName: endpoint,
                    columns: [
                        { 
                            field: 'moduleId', hidden: true 
                        },
                        {
                            field: 'name',
                        },
                        {
                            field: 'description',
                        }
                    ],
                    editable: EditableRowOps([endpoint, 'moduleId', permissions]),
                    detailPanel: [{
                        icon: 'settings',
                        tooltip: 'Show parameters',
                        render: (rowData) => <ModuleParamList moduleId={rowData.moduleId} />
                    }]
                }}
            />
        </div>
    );
};

export default ModuleList;
