import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate, useLocale, useSetLocale, showNotification, Title, useDataProvider } from 'react-admin';
import { CardContent, Button, Card, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import reduxModules from '../redux-modules';

const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    passwordLabel: { marginBottom: '10px'},
    container: { width: '20em', display: 'inline-block'},
    button: { margin: '1em' },
    textfield: { margin: '4px', width: '20em', height: '40px' },
    errorHelper: { "white-space": "pre-line", width: '110%' }
});

const localeStorage = {
    get: function(defaultValue) {
        return localStorage.getItem('locale') || defaultValue;
    },
    set: function(value) {
        localStorage.setItem('locale', value);
    }
};

const ReusableButton = ({ colorPredicateFn, dispatchAction, translateFn, classes }) => (
    <Button
        variant="contained"
        className={classes.button}
        color={colorPredicateFn()}
        onClick={dispatchAction}
    >
        {translateFn()}
    </Button>
);

const ThemeCard = ({ classes, translate, theme, changeThemeAction }) => {
    return (
        <CardContent>
            <div className={classes.label}>{translate('pos.theme.name')}</div>
            <ReusableButton
                classes={classes}
                colorPredicateFn={() => theme === 'light' ? 'primary' : 'default'}
                dispatchAction={() => changeThemeAction('light')}
                translateFn={() => translate('pos.theme.light')}
            />
            <ReusableButton
                classes={classes}
                colorPredicateFn={() => theme === 'dark' ? 'primary' : 'default'}
                dispatchAction={() => changeThemeAction('dark')}
                translateFn={() => translate('pos.theme.dark')}
            />
        </CardContent>
    );
};

const I18NCard = ({ classes, translate, locale, setLocaleAndNotify }) => {
    return (
        <CardContent>
            <div className={classes.label}>{translate('pos.language')}</div>
            <ReusableButton
                classes={classes}
                colorPredicateFn={() => (localeStorage.get(locale) === 'en' ? 'primary' : 'default')}
                dispatchAction={() => setLocaleAndNotify('en')}
                translateFn={() => 'en'}
            />
            <ReusableButton
                classes={classes}
                colorPredicateFn={() => (localeStorage.get(locale) === 'lt' ? 'primary' : 'default')}
                dispatchAction={() => setLocaleAndNotify('lt')}
                translateFn={() => 'lt'}
            />
        </CardContent>
    );
};

const ChangePasswordCard = ({ classes, translate, changePasswordAndNotify }) => {
    const [oldPw, setOldPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [newPwConfirm, setNewPwConfirm] = useState('');
    const [newPwError, setNewPwError] = useState('');

    const ValidatePassword = (value) => {
        setNewPw(value);
        var errors = "";
        if (value == "") {
            errors = translate('pos.changePassword.newPwEmpty') + ". ";
        }
        else { 
            if (value.length < 6) {
                errors = errors + translate('pos.changePassword.newPwShort') + ". \n";
            }
            if (value.toUpperCase() === value) {
                errors = errors + translate('pos.changePassword.newPwNoLower') + ". \n";
            }
            if (value.toLowerCase() === value) {
                errors = errors + translate('pos.changePassword.newPwNoUpper') + ". \n";
            }
            if (!/\d/.test(value)) { //is there a digit?
                errors = errors + translate('pos.changePassword.newPwNoNumber') + ". \n";
            }
        }
        setNewPwError(errors);
    };

    return (
        <CardContent>
            <div className={classes.container}>
                <div className={classes.passwordLabel}>{translate('pos.changePassword.label')}</div>
                <TextField 
                    className={classes.textfield} 
                    value={oldPw} 
                    label={translate('pos.changePassword.oldPw')} 
                    variant="outlined" 
                    size="small" 
                    type="password"
                    onInput={ e=>setOldPw(e.target.value) }
                />
                <TextField 
                    className={classes.textfield} 
                    value={newPw} 
                    label={translate('pos.changePassword.newPw')} 
                    variant="outlined" 
                    size="small" 
                    type="password"
                    error={newPwError !== ""}
                    helperText={<span className={classes.errorHelper}>{newPwError}</span>}
                    onInput={ e=> ValidatePassword(e.target.value) }
                />
                <TextField 
                    className={classes.textfield} 
                    value={newPwConfirm} 
                    label={translate('pos.changePassword.newPwConfirm')} 
                    variant="outlined" 
                    size="small" 
                    type="password"
                    onInput={ e=>setNewPwConfirm(e.target.value) }
                    error={newPw !== newPwConfirm}
                    helperText={newPw !== newPwConfirm ? translate('pos.changePassword.newPwMatchError') : ' '}
                />
                <ReusableButton
                    classes={classes}
                    colorPredicateFn={() => 'default' }
                    dispatchAction={() => (newPw != "" && newPw === newPwConfirm && newPwError == "") && changePasswordAndNotify(oldPw, newPw)}
                    translateFn={() => translate('pos.changePassword.confirm')}
                />
            </div>
        </CardContent>
    );   
};

const Configuration = () => {
    const dispatch = useDispatch();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useSelector(state => state.themeReducer);
    const dataProvider = useDataProvider();

    const setLocaleAndNotify = (locale) => {
        localeStorage.set(locale);
        setLocale(locale);

        dispatch(
            showNotification('notifications.localeChange', 'info', {
                autoHideDuration: 2000,
                messageArgs: { locale: locale.toUpperCase() },
            })
        );
    };

    const changeThemeAction = (theme) => dispatch(reduxModules.changeTheme(theme));

    const changePasswordAndNotify = (oldPw, newPw) => {
        dataProvider.create('identity/changemypassword',        
        {
            data: 
            {
                CurrentPassword: oldPw,
                NewPassword: newPw
            }
        }).then((json) => {
                dispatch(
                    showNotification('notifications.passwordChangeSuccess', 'info', {
                        autoHideDuration: 5000
                    }));
            })
            .catch((error) => {
                let errorMsg;
                if (error.info.status == 422 && JSON.stringify(error.info.body).includes('PasswordMismatch')){
                    errorMsg = 'notifications.passwordOldIncorrect';
                }
                else {
                    errorMsg = JSON.stringify(error.info.body);     
                }
                dispatch(
                    showNotification(errorMsg, 'error', {
                        autoHideDuration: 5000
                }));   
            }            
        )         
    };



    return (
        <Card>
            <Title title={'pos.configuration'} />
            <ThemeCard {...{ classes, translate, theme, changeThemeAction }} />
            <I18NCard {...{ classes, translate, locale, setLocaleAndNotify }} />
            <ChangePasswordCard {...{ classes, translate, changePasswordAndNotify }} />
        </Card>
    );
};

export default Configuration;
