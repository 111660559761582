import themeModule from './configuration';
import specialistModule from './specialists';
import tableModule from './enhancedTable';
import sessionModule from './rehab_sessions';
import devicesModule from './lookupData';
import assignedModule from './assignedData';
import paramsModule from './moduleParameters';

export default {
    ...{
        ...themeModule.actions,
        ...specialistModule.actions,
        ...tableModule.actions,
        ...sessionModule.actions,
        ...devicesModule.actions,
        ...assignedModule.actions,
        ...paramsModule.actions
    },
    reducers: {
        ...themeModule.reducers,
        ...specialistModule.reducers,
        ...tableModule.reducers,
        ...sessionModule.reducers,
        ...devicesModule.reducers,
        ...assignedModule.reducers,
        ...paramsModule.reducers
    },
    sagas: [
        ...themeModule.sagas
    ],
};
