import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, Title } from 'react-admin';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import createDataExtension from '../dataExtension';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';
import reduxModules from '../redux-modules';
import AssignedCheckbox from '../enhancedTable/AssignedCheckbox';
import ModuleParamList from '../modules/ModuleParamList';
import { CopyToClipboard } from '../helpers/clipboard';

const PatientsList = (props) => {
    const endpoint = 'patients';
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    const dataExtension = createDataExtension(dataProvider, dispatch, 'departments', 'departmentId', 'name');
    const departmentsLookupTable = useSelector(state => state.lookupReducer.departments);
    dataExtension.useLookupNames(departmentsLookupTable);

    const assigned = useSelector(state => state.assignedReducer['patientModules']);

    const handleResetPasswordAction = (rowData) => { 
        dataProvider.create('identity/resetuserpassword?userid=' + rowData.id, { data: null }).then(json => {
            const passwd = json["data"];
            CopyToClipboard(passwd)
                .then(() => alert("New password: " + passwd + "\nPassword successfully copied to clipboard."))
                .catch((error) => alert("Failed to copy text. Reason: " + error));
        });
    };

    return (
        <div className="ListLayoutFullWidth">
            <Title title='resources.patients.name' />
            <EnhancedTable
                {...{
                    customActions: [{
                        icon: 'cached',
                        tooltip: 'Reset patient password',
                        onClick:  (e, rowData) => handleResetPasswordAction(rowData),
                        disabled: !(permissions.update) //workaround to still have "actions" column, because otherwise columns get messed up when adding new Patient
                    }],
                    title: 'resources.patients.name',
                    tableName: endpoint,
                    columns: [
                        { field: 'id', hidden: true },
                        { field: 'userName' },
                        { field: 'currentDepartmentId', lookupTable: 'departments' },
                    ],
                    editable: EditableRowOps([endpoint, 'id', permissions], { onRowAdd: newData => handleResetPasswordAction(newData) }),
                    customOptions: {
                        exportButton: true,
                        rowStyle: rowData => ({
                            backgroundColor: ((assigned && assigned.parentId) === rowData.id) ? '#CCC' : null
                        })
                    },
                    onRowClick: (evt, rowData) => {
                        dataProvider.getList(`modules?patientId=${rowData.id}`)
                            .then(response => { 
                                let assignedModules = [];
                                for (let data of response.data) {
                                    assignedModules.push(data.moduleId);
                                }
                                dispatch(
                                    reduxModules.updateAssigned({
                                        ['patientModules']: {
                                            parentId: rowData.id,
                                            assignedRows: assignedModules
                                        }
                                    })
                                );
                            });
                    }
                }}
            />
            <EnhancedTable
                style={{ position: 'relative', top: '2%' }}
                {...{
                    title: 'resources.patients.patientModules',
                    tableName: 'modules',
                    columns: [
                        {
                            field: 'moduleId',
                            hidden: true,
                        },
                        {
                            field: 'name',
                        },
                        {
                            field: 'description',
                        },
                        {
                            field: 'assigned',
                            filtering: false,
                            sorting: false,
                            render: (rowData) => (
                                <AssignedCheckbox 
                                    color="default" 
                                    childId={rowData.moduleId} 
                                    endpoint="patientModules"
                                    parentIdField="patientId" 
                                    childIdField="moduleId" 
                                    dataProvider={dataProvider}
                                    dispatch={dispatch}
                                />
                            )
                        },
                    ],
                    // key: assigned && assigned.parentId,
                    detailPanel: [{
                        disabled: !assigned,
                        icon: 'settings',
                        tooltip: 'Show parameters',
                        render: (rowData) => <ModuleParamList moduleId={rowData.moduleId} />
                    }]
                }}
            />
        </div>
    );
};
export default PatientsList;
