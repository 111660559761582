import React from 'react';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import BoxPlotChart from './BoxPlotChart';
import TimeColumnChart from "./TimeColumnChart";

const MultiLevelTimeColumnAndBoxPlotCharts = ({ tableData }) => {
    const timeCharts = [];
    const timeLevels = Array.from(new Set(tableData.map(item => item.difficultyState)));
    timeLevels.forEach((difficultyState) => {
        const chartTitleOptions = { title: { text: `LVL ${difficultyState}`, align: "left" } };
        const columnChartData = tableData.filter(item => item.difficultyState === difficultyState);
        const boxPlotChartData = [{ x: difficultyState, y: columnChartData.map((item) => parseFloat(item.xoffset)) }];

        timeCharts.push(
            <Grid item>
                <TimeColumnChart titleOptions={chartTitleOptions} tableData={columnChartData} />
                <BoxPlotChart titleOptions={chartTitleOptions} tableData={boxPlotChartData} />
            </Grid>
        );
    });

    return (
        <Card>
            <CardHeader title="Time Charts" style={{backgroundColor: "#f9f9f9"}} />
            <CardContent>
                <Grid container>
                    { timeCharts } 
                </Grid>
                <TimeColumnChart tableData={tableData} />
            </CardContent>
        </Card>
    );
};

export default MultiLevelTimeColumnAndBoxPlotCharts;