import React from 'react';
import BoxPlotChart from "./BoxPlotChart";

const ReactionBoxPlotChart = ({ tableData }) => {
    const reactionBoxPlotSeries = tableData.reduce((acc, item) => {
        const reactionTime = parseInt(item.reactionTime);
        const currentMinute = Math.floor(parseInt(item.timeStamp) / 60_000);
        if (!acc[currentMinute])
            acc[currentMinute] = { x: currentMinute, y: [] };
        acc[currentMinute].y.push(reactionTime);
        return acc;
    }, []);

    if (reactionBoxPlotSeries.length == 0)
        return null;

    return (
        <BoxPlotChart titleOptions={{ title: { text: "Reaction Time Chart" } }} tableData={reactionBoxPlotSeries} />
    );
};

export default ReactionBoxPlotChart;