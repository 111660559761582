import React from 'react';
import { useDataProvider, Title } from 'react-admin';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import createEditableRowOps from '../editableRowOps';
import authProvider from '../authProvider';
import AssignedCheckbox from '../enhancedTable/AssignedCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import reduxModules from '../redux-modules';

const IllnessesList = props => {
    const endpoint = 'disabilities';
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);
    const permissions = authProvider.getPermissionsByResource(endpoint);

    const assigned = useSelector(state => state.assignedReducer['disabilityModules']);

    return (
        <div className="ListLayoutFullWidth">
            <Title title="resources.disabilities.name" />
            <EnhancedTable
                {...{
                    title: "resources.disabilities.name",
                    tableName: endpoint,
                    columns: [
                        { 
                            field: 'disabilityId', 
                            hidden: true },
                        {
                            field: 'name',
                        },
                        {
                            field: 'description',
                        }
                    ],
                    editable: EditableRowOps([endpoint, 'disabilityId', permissions]),
                    customOptions: {
                        rowStyle: rowData => ({
                            backgroundColor: ((assigned && assigned.parentId) === rowData.disabilityId) ? '#CCC' : null
                          })
                    },
                    onRowClick: (evt, rowData) => {
                        dataProvider.getList(`modules?disabilityId=${rowData.disabilityId}`)
                            .then(response => { 
                                let assignedModules = [];
                                for (let data of response.data) {
                                    assignedModules.push(data.moduleId);
                                }
                                dispatch(
                                    reduxModules.updateAssigned({
                                        ['disabilityModules']: {
                                            parentId: rowData.disabilityId,
                                            assignedRows: assignedModules
                                        }
                                    })
                                );
                             });
                    }
                }}
            />
            <EnhancedTable
                style={{ position: 'relative', top: '2%' }}
                {...{
                    title: 'resources.disabilities.disabilityModules',
                    tableName: 'modules',
                    columns: [
                        {
                            field: 'moduleId',
                            hidden: true,
                        },
                        {
                            field: 'name',
                        },
                        {
                            field: 'description',
                        },
                        {
                            field: 'assigned',
                            filtering: false,
                            sorting: false,
                            render: (rowData) =>  
                                <AssignedCheckbox 
                                    color="default" 
                                    childId={rowData.moduleId} 
                                    endpoint="disabilityModules"
                                    parentIdField="disabilityId" 
                                    childIdField="moduleId" 
                                    dataProvider={dataProvider}
                                    dispatch={dispatch}
                                /> 
                        },
                    ],
                    // key: assigned && assigned.parentId
                }}
            />
        </div>
    );
};

export default IllnessesList;
